<script setup lang="ts">
import {computed} from 'vue';
import {RouterLink} from 'vue-router';

import type {Configuration} from '@/types/config';
import type {TextNode} from '@/types/node';

import MenuBlock from '@/components/menu/MenuBlock.vue';
import AppTheme from '@/components/app/AppTheme.vue';

const props = defineProps<{
  config: Configuration;
  suggestion?: TextNode | null;
}>();

const title = computed(() => {
  return props.config.site.title || 'Lelkész.com';
});
const menu = computed(() => {
  const menu = props.config.menu?.header ?? [];
  if (props.suggestion?.nid && props.suggestion?.url) {
    menu.push({
      title: 'Napi ajánlat',
      url: props.suggestion.url,
      weight: 100,
    });
  }
  return menu;
});
</script>
<template>
  <header class="header">
    <input
      type="checkbox"
      class="header--menu--toggle hidden"
      id="header--menu--toggle"
    />
    <div class="header--content content">
      <div class="header--branding">
        <h1 class="header--logo">
          <RouterLink
            to="/"
            class="header--logo-link"
          >
            <template v-if="config.site.logo?.data">
              <img
                :src="config.site.logo?.data"
                :alt="title"
              />
            </template>
            <template v-else>{{ title }}</template>
          </RouterLink>
        </h1>
        <div
          v-if="config.site.slogan"
          class="header--slogan"
        >
          {{ config.site.slogan }}
        </div>
      </div>

      <label
        for="header--menu--toggle"
        class="header--menu--control"
        title="Menü"
      />
      <MenuBlock
        class-prefix="header"
        :items="menu"
      />
      <AppTheme />
    </div>
  </header>
</template>
