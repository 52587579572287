/**
 * Default connection handler to API.
 */
import type ConnectionInterface from '@/api/connection/ConnectionInterface';
import type {Configuration, ParamList} from '@/types/config';
import type {SiteItem, SitePreacher} from '@/types/sites';
import type {ResolvedUrl, SearchResultResponse, ConnectionSetup, TokenResult} from '@/types/rest';
import type {AxiosResponse, RawAxiosRequestConfig} from 'axios';
import type {PageNode, TextNode} from '@/types/node';
import type RestConInterface from '@/api/connection/RestConInterface';
import type {
  FeedbackMessageBody,
  FeedbackMessageData,
  FeedbackMessageResponse,
} from '@/types/feedback';
import type {
  CitationData,
  CitationMessageData,
  CitationNode,
  CitationResponse,
  CitationListResponse,
  CitationTagResult,
} from '@/types/citations';
import type {SzentirasApiResponse} from '@/types/tooltip';

export default class Connection implements ConnectionInterface {
  protected _host: string;
  protected _token: string = '';
  protected _config: ConnectionSetup;
  protected _rest: RestConInterface;
  protected cfg: Configuration | null = null;
  protected sites: SiteItem[] | null = null;
  protected preachers: SitePreacher[] | null = null;
  protected suggested: TextNode | boolean | null = null;
  protected requests: Map<string, Promise<AxiosResponse>> = new Map();

  constructor(config: ConnectionSetup, rest: RestConInterface) {
    this._config = {...config};
    this._host = this._config.baseUrl;
    this._rest = rest;
  }

  get rest() {
    return this._rest;
  }

  async getConfig(): Promise<Configuration> {
    if (!this.cfg) {
      const response = await this.getRequest<Configuration>('config', {
        url: '/api/v2/config',
        method: 'get',
      });
      this.cfg = {...response.data};
    }
    return {...this.cfg} as Configuration;
  }

  protected getRequest<T = unknown>(
    name: string,
    config: RawAxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    if (!this.requests.has(name)) {
      this.requests.set(name, this.rest.request(config));
    }
    return this.requests.get(name) as Promise<AxiosResponse<T>>;
  }

  async fetchToken(): Promise<string> {
    const response = await this.rest.request<string>({
      method: 'get',
      url: '/session/token',
    });
    this._token = response.data;
    this._rest.setToken(this._token);
    return this._token;
  }

  async getSites(): Promise<SiteItem[]> {
    if (!this.sites) {
      const response = await this.getRequest<SiteItem[]>('sites', {
        url: '/api/v2/sites',
        method: 'get',
      });
      this.sites = [...response.data];
    }
    return this.sites;
  }

  async getPreachers(): Promise<SitePreacher[]> {
    if (!this.preachers) {
      const response = await this.getRequest<SitePreacher[]>('preachers', {
        url: '/api/v2/preachers',
        method: 'get',
      });
      this.preachers = [...response.data];
    }
    return this.preachers;
  }

  async getResults(params: ParamList): Promise<SearchResultResponse> {
    const response = await this.rest.request<SearchResultResponse>({
      method: 'get',
      url: '/api/v2/list',
      params,
    });
    return {
      data: [...response.data.data],
      paging: {...response.data.paging},
    };
  }

  async resolveUrl(path: string): Promise<ResolvedUrl> {
    const response = await this.rest.request<{data: ResolvedUrl}>({
      method: 'get',
      url: '/api/v2/resolve',
      params: {
        path,
      },
    });
    return {...response.data.data};
  }

  async getContent(nid: string | number, cache = false): Promise<TextNode | PageNode> {
    const confg = {
      method: 'get',
      url: `/api/v2/node/${nid}`,
    };
    let response;
    if (cache) {
      response = await this.getRequest<TextNode | PageNode>(confg.url, confg);
    } else {
      response = await this.rest.request<TextNode | PageNode>(confg);
    }
    return {...response.data};
  }

  async getSuggestion(): Promise<TextNode | null> {
    if (this.suggested === null) {
      const response = await this.getRequest<TextNode>('daily-selection', {
        method: 'get',
        url: '/api/v2/daily-selection',
      });
      this.suggested = response.data.nid ? {...response.data} : false;
    }
    return (this.suggested as TextNode) || null;
  }

  async getToken(url: string, now: Date): Promise<TokenResult> {
    let token = '';
    const prefered = parseInt('' + now.getSeconds() / 10);
    let key = '';
    let error;

    try {
      // Get keys two times.
      const json = await this.rest.request({
        url,
        method: 'get',
        params: {
          time: now.toISOString(),
        },
      });
      if (Array.isArray(json.data) && json.data.length) {
        const tokens: string[] = json.data;
        token = tokens.shift() as string;
        key = tokens[prefered];
      }
    } catch (e) {
      error = e;
    }
    return {
      token,
      key,
      error,
    };
  }

  async sendFeedback(message: FeedbackMessageData, now: Date): Promise<FeedbackMessageResponse> {
    const {token, key, error} = await this.getToken('/api/v2/feedback', now);
    if (!token || !key) {
      const message = error?.response?.data?.message ?? 'Unknonw';
      console.warn('Key error:', {error, message});
      return {error: true, message};
    }

    await new Promise((resolve) => {
      setTimeout(() => {
        resolve(1);
      }, 500);
    });

    try {
      const resp = await this.rest.request<FeedbackMessageResponse>({
        url: `/api/v2/feedback/${token}`,
        method: 'post',
        data: {
          ...message,
          key,
        } as FeedbackMessageBody,
      });
      if (resp.data.error) {
        console.warn('Message success error:', resp.data.message);
      }
      return {...resp.data};
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      let message = 'Unknonw';
      let errors = undefined;
      if (e.response) {
        message = e.response?.data?.message || e.response.code;
        errors = e.response?.data?.errors || undefined;
      } else if (e.message) {
        message = e.message;
      }
      console.warn('Message failed error:', e, {message, errors});
      return {
        error: true,
        message,
        errors,
      };
    }
  }

  async sendCitation(citation: CitationData, now: Date): Promise<CitationResponse> {
    const {token, key, error} = await this.getToken('/api/v2/citation', now);
    if (!token || !key) {
      console.warn('Key error:', error);
      return {error: true, message: 'Key error'};
    }

    await new Promise((resolve) => {
      setTimeout(() => {
        resolve(1);
      }, 500);
    });

    try {
      const resp = await this.rest.request<CitationResponse>({
        url: `/api/v2/citation/${token}`,
        method: 'post',
        data: {
          ...citation,
          key,
        } as CitationMessageData,
      });
      if (resp.data.error) {
        console.warn('Citation success error:', resp.data.message);
      }
      return resp.data as unknown as CitationResponse;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      let message = 'Unknonw';
      let errors = undefined;
      if (e.response) {
        message = e.response?.data?.message || e.response.code;
        errors = e.response?.data?.errors || undefined;
      } else if (e.message) {
        message = e.message;
      }
      console.warn('Citation failed error:', e, {message, errors});
      return {
        error: true,
        message,
        errors,
      };
    }
  }

  async searchCitationTag(s: string): Promise<CitationTagResult[]> {
    if (s.length < 3) {
      return [];
    }
    const confg = {
      method: 'get',
      url: '/api/v2/citation-tags',
      params: {
        s,
      },
    };
    const response = await this.rest.request(confg);
    return (response.data ?? []) as CitationTagResult[];
  }

  async getCitationsForNode(preaching: number): Promise<CitationNode[]> {
    const confg = {
      method: 'get',
      url: '/api/v2/citations',
      params: {
        preaching,
      },
    };
    const response = await this.rest.request<{data: CitationNode[]}>(confg);
    return [...response.data.data] as CitationNode[];
  }

  async getCitationList(params: ParamList): Promise<CitationListResponse> {
    const response = await this.rest.request<CitationListResponse>({
      method: 'get',
      url: '/api/v2/citations',
      params,
    });
    return {
      data: [...response.data.data],
      paging: {...response.data.paging},
    };
  }

  async fetchSzentirasApi(url: string): Promise<SzentirasApiResponse> {
    const response = await this._rest.outRequest<SzentirasApiResponse>({
      method: 'get',
      url,
    });
    return {...response.data};
  }
}
