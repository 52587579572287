import {createApp} from 'vue';
import {createHead} from '@vueuse/head';
import {createPinia} from 'pinia';
import router from '@/router';
import LelkeszApp from '@/LelkeszApp.vue';

export function createLelkeszApp() {
  const lelkeszApp = createApp(LelkeszApp);

  lelkeszApp.use(createPinia());
  lelkeszApp.use(router);
  lelkeszApp.use(createHead());

  lelkeszApp.mount('#app');
  return lelkeszApp;
}
