<script setup lang="ts">
import {onMounted, ref} from 'vue';

const userTheme = ref('light');

const toggleTheme = () => {
  const activeTheme = localStorage.getItem('predikacio_theme');
  if (activeTheme === 'light') {
    setTheme('dark');
  } else {
    setTheme('light');
  }
};

const getTheme = () => {
  return localStorage.getItem('predikacio_theme');
};

const setTheme = (theme: string) => {
  localStorage.setItem('predikacio_theme', theme);
  userTheme.value = theme;
  document.documentElement.className = theme;
};

const getMediaPreference = () => {
  const hasDarkPreference = window.matchMedia('(prefers-color-scheme: dark)').matches;
  if (hasDarkPreference) {
    return 'dark';
  } else {
    return 'light';
  }
};

onMounted(() => {
  const initUserTheme = getTheme() || getMediaPreference();
  setTheme(initUserTheme);
});
</script>
<template>
  <div class="app--theme--toggle">
    <input
      @change="toggleTheme"
      id="checkbox"
      type="checkbox"
      class="switch-checkbox"
    />
    <label
      for="checkbox"
      class="switch-label"
    >
      <div
        class="switch-toggle"
        :class="{'switch-toggle-checked': userTheme === 'dark'}"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="8"
          height="8"
          class="moon"
        >
          <path
            d="M5.3 7.785c-1.501.513-3.214.069-4.278-1.109a4.03 4.03 0 0 1-.656-4.359A4.05 4.05 0 0 1 4.072.001C2.946 2.476 5.399 5.203 8 4.32a4.04 4.04 0 0 1-2.7 3.465z"
            fill="#fff"
            fill-rule="evenodd"
          />
        </svg>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="10"
          class="sun"
        >
          <path
            d="M5.356 9.644V8.536c0-.197-.16-.356-.356-.356s-.356.16-.356.356v1.108c0 .197.16.356.356.356s.356-.16.356-.356zm1.892-1.892l.783.783c.139.139.365.139.504 0s.139-.365 0-.504l-.784-.784c-.139-.139-.365-.139-.504 0s-.139.365 0 .504zm-5.28.783l.784-.783c.139-.139.139-.365 0-.504s-.365-.139-.504 0l-.784.784c-.139.139-.139.365 0 .504s.365.139.504 0zM4.991 2.52c-1.387 0-2.513 1.126-2.513 2.514s1.126 2.513 2.513 2.513 2.514-1.126 2.514-2.513S6.378 2.52 4.991 2.52zm3.545 2.836h1.108c.197 0 .356-.16.356-.356s-.16-.356-.356-.356H8.536c-.197 0-.356.16-.356.356s.16.356.356.356zm-8.18 0h1.108c.197 0 .356-.16.356-.356s-.16-.356-.356-.356H.356C.16 4.644 0 4.803 0 5s.16.356.356.356zm1.108-3.388l.784.784c.139.139.365.139.504 0s.139-.365 0-.504l-.784-.783c-.139-.139-.365-.139-.504 0s-.139.365 0 .504zm6.288.784l.784-.784c.139-.139.139-.365 0-.504s-.365-.139-.504 0l-.783.783c-.139.139-.139.365 0 .504s.365.139.504 0zM5.356 1.464V.356C5.356.16 5.197 0 5 0s-.356.16-.356.356v1.108c0 .197.16.356.356.356s.356-.16.356-.356z"
            fill="#fff"
            fill-rule="evenodd"
          />
        </svg>
      </div>
    </label>
  </div>
</template>
