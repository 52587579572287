import {createRouter, createWebHistory} from 'vue-router';

import type {RouteLocationNormalized} from 'vue-router';
import type {ResolvedUrl} from '@/types/rest';

import {customParseQuery, customStringifyQuery} from '@/router/query-params';
import ApiConnection from '@/api';

const homeView = {
  path: '/',
  name: 'search',
  component: () => import('../views/home/HomeView.vue'),
};
const nodeListRedirect = {
  path: '/node',
  redirect: '/',
};
const nodeView = {
  name: 'node_view',
  path: '/node/:id',
  component: () => import('../views/node/NodeView.vue'),
  props: true,
};
const citationListView = {
  path: '/idezetek',
  name: 'citations',
  component: async () => {
    const configuration = await ApiConnection.getConfig();
    if (configuration.site.citations) {
      return import('../views/citations/CitationsView.vue');
    }
    return import('../views/404/Error404NotFound.vue');
  },
};
const citationListRedirect = {
  path: '/citations',
  redirect: citationListView.path,
};

export async function notFoundBeforeEnter(to: RouteLocationNormalized) {
  let found: ResolvedUrl | undefined;
  try {
    found = await ApiConnection.resolveUrl(to.path);
  } catch {
    return true;
  }

  if (found?._pattern) {
    const alias = {
      ...nodeView,
      name: `alias_${to.fullPath}`,
      path: to.path,
      props: () => {
        return found?.props ?? {};
      },
    };
    router.addRoute(alias);
    return {path: alias.path};
  }
  return true;
}
const notFound = {
  path: '/:pathMatch(.*)*',
  name: 'not-found',
  component: () => import('../views/404/Error404NotFound.vue'),
  beforeEnter: notFoundBeforeEnter,
};

export const routes = [
  homeView,
  nodeListRedirect,
  {
    ...nodeView,
    alias: ['/predikacio/:id'],
  },
  citationListView,
  citationListRedirect,
  notFound,
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,

  scrollBehavior() {
    return {top: 0};
  },

  parseQuery: customParseQuery,
  stringifyQuery: customStringifyQuery,
});

export default router;
