import type {UrlString} from '@/types/rest';

export function isEmbedded() {
  const u = new URL(document.location.href);
  const val = u.searchParams.get('EMBEDDED') ?? 0;
  return parseInt(val.toString()) === 1;
}

export function redirectTo(url: UrlString) {
  window.location.href = url;
}
