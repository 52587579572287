<script setup lang="ts">
import {computed} from 'vue';

import type {Configuration, MenuItem} from '@/types/config';
import type {SiteItem} from '@/types/sites';

import MenuBlock from '@/components/menu/MenuBlock.vue';

const props = defineProps<{
  config: Configuration;
  sites: SiteItem[];
}>();

const menuItems = computed<MenuItem[]>(() => {
  return props.sites
    .filter((site) => {
      return Boolean(site.field_link);
    })
    .map((site) => {
      return {
        title: site.field_link.replace(/https?:\/\/(.*?)\/?$/gm, '$1'),
        url: site.field_link,
        weight: site.weight,
      };
    })
    .sort((a, b) => {
      return a.weight - b.weight;
    });
});
</script>
<template>
  <footer
    class="footer"
    :data-size="config.site.embedded_site ? 'small' : 'normal'"
  >
    <div class="footer--content content">
      <div
        v-if="config.site.copyright_raw"
        class="footer--copyright"
      >
        <h3 class="footer--block-title">Információ</h3>
        <p v-html="config.site.copyright_raw" />
      </div>

      <div
        v-if="!config.site.embedded_site"
        class="footer--nav--wrapper"
      >
        <MenuBlock
          v-if="menuItems.length"
          title="Kapcsolódó oldalak"
          class-prefix="footer"
          class="footer--nav--sites"
          :items="menuItems"
        />
        <MenuBlock
          v-if="config.menu.footer?.length"
          class-prefix="footer"
          class="footer--nav--footer"
          :items="config.menu.footer"
        />
      </div>
    </div>
  </footer>
</template>
