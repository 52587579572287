import type ConnectionInterface from '@/api/connection/ConnectionInterface';

import Connection from '@/api/connection';
import ConnectionRest from '@/api/connection/rest';
import {getBaseUrl} from '@/api/connection/baseUrl';

const config = {
  baseUrl: getBaseUrl(),
  user: import.meta.env.VITE_API_USER,
};

const restConnection: ConnectionRest = new ConnectionRest(config);
const ApiConnection: ConnectionInterface = new Connection(config, restConnection);

export default ApiConnection;
