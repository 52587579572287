import type {MenuItem} from '@/types/config';

export function createClasses(classes: string[], prefix?: string): string[] {
  const result = [...classes];
  if (prefix) {
    classes.forEach((item) => {
      result.push(prefix + '--' + item);
    });
  }
  return result;
}

export function createLinkRel(item: MenuItem): string | undefined {
  const rel = [];
  if (item.attributes?.nofollow) {
    rel.push('nofollow');
  }
  if (rel.length) {
    return rel.join(' ');
  }
  return undefined;
}

export function hasChildren(item: MenuItem): boolean {
  return getChildren(item).length > 0;
}
export function getChildren(item: MenuItem): MenuItem[] {
  return item.children ?? [];
}
export function itemClasses(item: MenuItem, prefix?: string): string[] {
  const classes = createClasses(['menu--item'], prefix);
  if (hasChildren(item)) {
    classes.push(...createClasses(['menu--item-with-children'], prefix));
  }
  return classes;
}

export function getNextDepth(depth?: number): number {
  const currentDepth = depth ?? 0;
  return currentDepth + 1;
}
